<template>
  <div id="app">
    <div class="main_bg"></div>
    <Header />
    <br/>
    <div style="text-align:center;color:#ffffff;position: relative;font-size: 10px;">
      <h1>{{ $t('home.bottom_nav_2') }}</h1>
    </div>
    <Bottom />
  </div>
</template>

<script>
import Header from "@/components/Header"
import Bottom from "@/components/Bottom"

export default {
  name: "History",
  // data() {
  //   return {
  //   };
  // },
  components: {
    Header,
    Bottom
  }
}

</script>

<style scoped>
.main_bg {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
    color: #ffffff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
    background-size:100% 100%;
    background-repeat: no-repeat;
    position: fixed;
}
</style>